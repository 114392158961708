var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"systemInfo wr"},[_c('div',{staticClass:"info-a wr f-cb"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"pinfo-container wr swiper-container-horizontal swiper-container-android"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide-active"},[_c('img',{attrs:{"src":require('@/img/irrigationSystems.png'),"alt":""}})])])])]),_vm._m(0)]),_c('div',{staticClass:"view"},[_c('div',{staticClass:"info-b wr"},[_c('div',{staticClass:"tit-48 mc lh1 hnb"},[_vm._v("产品特点")]),_c('div',{staticClass:"list f-cb"},[_c('div',{staticClass:"li"},[_c('div',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"土壤湿度监测"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("土壤湿度监测")])]),_vm._m(1)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"自动化灌溉调度"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("自动化灌溉调度")]),_vm._m(2)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"变量灌溉技术"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("变量灌溉技术")]),_vm._m(3)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"数据驱动的灌溉建议"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("数据驱动的灌溉建议")]),_vm._m(4)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"节水策略和评估"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("节水策略和评估")]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"tit-60 mc hnb"},[_vm._v("节水灌溉系统")]),_c('div',{staticClass:"tit-18 t1 hnb"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 节水灌溉系统是专为现代农业和城市绿化设计的高效水资源管理方案，旨在通过精确控制灌溉，节约水资源并提高效率。系统集成传感器技术、智能调度算法和实时环境监测，确保水分精确供给作物所需，最小化浪费。 ")])]),_c('p',{staticStyle:{"line-height":"1.75em"}},[_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统通过在不同土壤类型中部署传感器，实时监测土壤湿度，确保灌溉量符合作物需求，避免浪费和过度灌溉，从而促进作物健康生长。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统结合气象信息和实时土壤湿度数据，自动调整灌溉计划，利用智能算法预测未来水需求，优化水资源分配，减少人工干预，提高灌溉效率。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 采用变量喷洒技术，根据不同地块的水分需求和环境条件调整水量，尤其适用于地形复杂或土壤类型多变的农田，提高水资源利用率，减少灌溉不均匀带来的问题。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统通过环境和作物生长数据提供基于大数据分析的灌溉建议，帮助农户和管理者做出科学决策。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统支持实时节水操作并评估节水效果，确保灌溉活动可持续发展，促进环境和经济双重优化。 ")])])])
}]

export { render, staticRenderFns }